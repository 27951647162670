// 是否为ios
const isIos =
  !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ||
  navigator.userAgent.includes("iPhone"); // ios终端

// 是否为安卓
const isAndroid =
  navigator.userAgent.indexOf("Android") > -1 ||
  navigator.userAgent.indexOf("Linux") > -1; // g

// 是否是 Chrome 浏览器
const isChrome = navigator.userAgent.indexOf("Chrome") !== -1;

// 是否是微信
const isWxBroser =
  navigator.userAgent.toLowerCase().indexOf("micromessenger") != -1;

// 是否跳转到应用市场
const toMarket =
  navigator.userAgent.toLowerCase().includes("harmonyos") || // 鸿蒙
  // navigator.userAgent.toLowerCase().includes("huawei") || // 华为
  navigator.userAgent.toLowerCase().includes("heytap") || // OPPO 手机
  navigator.userAgent.toLowerCase().includes("oppo"); // OPPO 手机

// 是否在梦饷集团的App内
const isInOurApp = navigator.userAgent.toLowerCase().includes("-akc");

// 是否是饷App的落地页
const isXapp = window.location.pathname.toLowerCase().indexOf("/x") == 0;

// 是否需要进入应用宝详情
const isToYingYongBao = navigator.userAgent.toLowerCase().includes("qqnews");

const getQueryString = (name) => {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
};

const downAKCApp = () => {
  let c = getQueryString("c");
  if (null != c && c == '1001') {
    // 来自官网渠道，返回官网渠道专用链接
    if (isIos) {
      return 'https://uri6.com/tkio/R3qERza';
    }
    return 'https://uri6.com/tkio/u6r6zma';
  }
  if (isIos) {
    return "https://apps.apple.com/cn/app/id1336929842";
  }
  /*if (isToYingYongBao) {
    return "https://sj.qq.com/myapp/detail.htm?apkName=com.aikucun.akapp";
  }*/
  return toMarket
    ? "market://details?id=com.aikucun.akapp"
    : "https://devdevnew-oss.aikucun.com/android/aikucun/last/aikucun-1001.apk";
};
const downXApp = () => {
  if (isIos) {
    return "https://apps.apple.com/cn/app/id1578481380";
  }
  /*if (isToYingYongBao) {
    return "https://sj.qq.com/myapp/detail.htm?apkName=com.mengxiang.xapp";
  }*/
  let ua = navigator.userAgent.toLowerCase();
  let tm = toMarket;

  // 爱库存内 && vivo 不打开应用市场
  if (isInOurApp && ua.includes("vivo")) {
    tm = false;
  }

  return tm
    ? "market://details?id=com.mengxiang.xapp"
    : "https://devdevnew-oss.aikucun.com/android/xapp/last/xapp-1001.apk";
};

// 下载 App
const downloadApp = (type) => {
  let isDownloadXapp = isXapp;
  if (type) {
    isDownloadXapp = type === "xapp";
  }
  let url = isDownloadXapp ? downXApp() : downAKCApp();
  window.location.href = url;
};

const getDefaultScheme = () => {
  return isXapp ? "xapp://mengxiang.com" : "akapp://aikucun.com";
};

// 获取 scheme
const getScheme = () => {
  let scheme = getQueryString("scheme");
  if (null == scheme || scheme.length == 0) {
    scheme = getDefaultScheme();
    let shotCode = window.location.pathname.split('/').pop();
    let query = window.location.search.substr(1);
    if (shotCode && shotCode.length > 0) {
      scheme = `${scheme}/s?sc=${shotCode}`;
    }
    if (query && query.length > 0) {
      if (scheme.indexOf("?") > 0) {
        // 判断url地址中是否包含?字符串
        scheme = `${scheme}&${query}`;
      } else {
        scheme = `${scheme}?${query}`;
      }
    }
  }
  return scheme;
};

// 创建 iframe
const createIframe = (() => {
  var iframe;
  return () => {
    if (iframe) {
      return iframe;
    } else {
      iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      return iframe;
    }
  };
})();

// 尝试打开 App
const openAppIfPossible = () => {
  let scheme = getScheme();
  console.log('scheme', scheme);
  if (isIos) {
    window.location.href = scheme;
    return;
  }
  if (isAndroid) {
    if (isChrome) {
      window.location.href = scheme;
      return;
    }
  }
  let openIframe = createIframe();
  openIframe.src = scheme;
};

export { isIos, isAndroid, isWxBroser, toMarket, isInOurApp, isXapp, getQueryString, downloadApp, openAppIfPossible };
